import React from "react"
import Markdown from "markdown-to-jsx"

import mailto from "./mailto"

import data_dry from "~data/dry.yml"

const DRY = (props) => {
  const key = props.children
  const env_node = process.env.NODE_ENV

  // empty payload, show no text.
  // we can then scan for <TAG> </TAG> in source files in tests
  if (!key) {
    return <><code title="dry_empty_key">{env_node === "development" ? "DRY[-no-key-]" : ""}</code></>
  }

  const content = data_dry[key] || ""

  // catch nothings before parse, dont bother trying to md parse nothing
  // we can then scan for `unknown_key` in the output in tests
  if (content === "") {
    return <code title="dry_unknown_key">{env_node === "development" ? <>DRY[{key}]</> : ""}</code>
  }

  return (
    <Markdown
      options={{
        overrides: {
          mailto: {
            component: mailto
          }
        }
      }}
    >
      {content}
    </Markdown>
  )
}

export default DRY
