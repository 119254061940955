import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { SideMenu } from "@fastly/consistently"
import { SideMenu, SideMenuItem, SideMenuContent, SideMenuTriggerLink } from "@fastly/consistently-vanilla"
import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import Awesome from "~components/awesome"
import DRY from "~components/dry"

import FrontmatterPageContext from "~context/FakePageContext"

import { mainArea, main } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"
import LayoutCore from "./layout-core"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

const LayoutDefault = ({ pageContext }) => {
  const { frontmatter, body } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto, Awesome, DRY, CodeBlock, CodeBlock, pre: CodeBlock }

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <SideMenuSwitcher />

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-content">
              <MDXProvider components={mdx_components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutDefault

export const Head = (props) => {
  return (
    <>
      <title>{`${props.pageContext.frontmatter.title ?? "Fastly Docs"}`}</title>
      <CommonHead />
      <SeoHead {...props} />
      <FavHead />
    </>
  )
}
